const listingCategories: Array<string> = [
  "all",
  "collabs",
  "cfp",
  "forhire",
  "education",
  "jobs",
  "mentors",
  "mentees",
  "forsale",
  "events",
  "misc",
  "products",
];

export default listingCategories;
