import FadeIn from "react-fade-in";

export default function Conduct() {
  return (
    <FadeIn delay={100}>
      <div className="jumbotron">
        <h2>Code of Conduct</h2>
        <p>
          Nam optio aspernatur blanditiis, sit pariatur itaque suscipit? Neque
          quisquam quam exercitationem ut sapiente laborum unde pariatur
          provident quis hic cum repellat veritatis, itaque velit optio quo,
          placeat consequuntur earum a maiores vitae obcaecati, dolorum
          perspiciatis? Optio rem ad quam autem quas consectetur itaque ipsam
          perferendis sequi libero repellat eveniet iste, quibusdam facilis quos
          fugit tenetur? Esse soluta culpa porro! Assumenda, at.
        </p>
        <h2>Our Pledge</h2>
        <p>
          Perspiciatis neque ullam nostrum architecto enim animi quidem
          expedita, aut odio cumque laboriosam deleniti consectetur, laudantium
          eveniet natus mollitia, adipisci minima alias tenetur porro nesciunt?
          Repellat voluptatem numquam deserunt facilis ab quidem distinctio?
          Necessitatibus dolore eius asperiores ratione dolorum soluta rem optio
          laudantium magni facilis!
        </p>
        <h2>Our Standards</h2>
        <p>
          Incidunt accusantium cupiditate, harum facilis atque sequi beatae qui
          exercitationem:
        </p>
        <ul>
          <li>A quibusdam debitis fugit</li>
          <li>Qui in omnis sapiente deserunt voluptatem</li>
          <li>Facere ipsa ad officia unde </li>
          <li>Libero magni nesciunt similique</li>
          <li>
            Facilis esse, sint debitis iure expedita officia culpa vitae neque
            cupiditate nesciunt voluptatem eveniet enim molestiae
          </li>
          <li>
            A quibusdam debitis fugit, qui in omnis sapiente deserunt voluptatem
          </li>
        </ul>
        <p>
          Libero magni cupiditate nesciunt similique architecto iusto placeat,
          molestias possimus totam cum voluptatibus: Lorem ipsum dolor sit amet
          consectetur adipisicing elit. Amet, eligendi ex. Vitae mollitia dolor
          quasi natus nesciunt, reprehenderit quis corporis debitis dolore
          impedit animi est? Architecto earum, saepe recusandae quae dicta
          assumenda? Inventore atque illo itaque voluptates repudiandae
          laboriosam vel nobis totam provident cupiditate, temporibus, culpa
          quasi assumenda pariatur. Ipsam dolorem, nam earum perspiciatis harum
          doloribus accusamus aspernatur officia autem.
        </p>
        <ul>
          <li>Recusandae laudantium quis nulla fugia Nulla eius cumque</li>
          <li>Necessitatibus dolore eius rem optio</li>
          <li>
            Optio rem ad quam autem minima alias tenetur porro nesciunt quas
            consectetur itaque
          </li>
          <li>
            Ipsam dolorem, nam earum perspiciatis harum doloribus accusamus
            aspernatur
          </li>
          <li>
            Architecto earum, saepe recusandae quae dicta assumenda vitae
            mollitia dolor quasi natus nesciunt
          </li>
        </ul>
        <h2>Enforcement</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed minima
          sapiente iusto possimus et rerum at temporibus! Ratione aperiam ipsam,
          asperiores ducimus veniam blanditiis eius reprehenderit delectus odit,
          laborum inventore! Aliquam amet voluptas minus aut. Modi repellat
          nulla optio unde delectus sapiente error deserunt nisi laudantium
          praesentium corporis, architecto necessitatibus at dolore tempore
          iste. Facere, cupiditate velit? Error veniam hic porro atque adipisci
          minima mollitia dolore quam sequi id? Laborum illo error at nostrum
          nesciunt.
        </p>
        <h2>Attribution</h2>
        <p>Amet eligendi ex vitae mollitia dolor quasi natus:</p>
        <ul>
          <li>
            <a href="/">Reprehenderit quis corporis</a>
          </li>
          <li>
            <a href="/">Nulla optio unde delectus sapiente error</a>
          </li>
          <li>
            <a href="/">Debitis dolore impedit animi est</a>
          </li>
        </ul>
      </div>
    </FadeIn>
  );
}
